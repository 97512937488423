<template>
  <div>
    <b-modal
      id="modal-input-akses"
      size="lg"
      centered
      title="Tambah Data Master Akses"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Kode Akses <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('kode_akses')"
            type="text"
            v-model="$v.is_data.kode_akses.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Akses <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('nama_akses')"
            type="text"
            v-model="$v.is_data.nama_akses.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            URL <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('url')"
            type="text"
            v-model="$v.is_data.url.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tipe Akses <span class="text-danger">*</span>
          </template>
          <Multiselect
            id="tipe_akses"
            v-model="$v.is_data.tipe_akses.$model"
            :options="list_tipe"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih Tipe Akses --"
            size="sm"
          ></Multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Head <span class="text-danger">*</span>
          </template>
          <b-form-input
            :disabled="is_data == 'menu'"
            :state="checkIfValid('head')"
            type="text"
            v-model="$v.is_data.head.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Sequence <span class="text-danger">*</span>
          </template>
          <b-form-input
            :state="checkIfValid('sequence')"
            type="number"
            v-model="$v.is_data.sequence.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modal-input-akses')"
        >
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
          >{{ button }}</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: ["count"],
  data() {
    return {
      is_data: {
        kode_akses: null,
        nama_akses: null,
        url: null,
        tipe_akses: null,
        head: null,
        sequence: this.counts,
      },
      list_tipe: ["menu", "submenu"],
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
    };
  },
  watch: {
    count: function (newV) {
      this.is_data.sequence = newV;
    },
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pid() {
      return this.$store.state.puskesmas_id
        ? this.$store.state.puskesmas_id
        : null;
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      kode_akses: { required },
      nama_akses: { required },
      url: { required },
      tipe_akses: { required },
      head: {
        required: requiredIf(function () {
          return this.is_data.tipe_akses == "submenu";
        }),
      },
      sequence: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      if (vm.pid) {
        vm.busy = true;
        vm.button = "Mohon Tunggu";
        vm.$axios
          .post("/ms_akses/register", {
            ...vm.is_data,
            puskesmas_id: vm.pid,
          })
          .then((res) => {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$v.$reset();
              vm.$emit("alertFromChild", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN MASTER AKSES",
                showing: true,
              });
              this.$bvModal.hide("modal-input-akses");
              // this.resetModal();
            } else {
              vm.button = "Simpan";
              vm.busy = false;
              vm.$emit("alertFromChild", {
                variant: "danger",
                msg: res.data.message.toUpperCase(),
                showing: true,
              });
            }
          })
          .catch(() => {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: "TERJADI KESALAHAN PADA SERVER",
              showing: true,
            });
          });
      } else {
        vm.$emit("alertFromChild", {
          variant: "danger",
          msg: "PUSKESMAS ID TIDAK TERDETEKSI",
          showing: true,
        });
      }
    },
    resetModal() {
      this.$v.$reset();
      this.is_data = {
        kode_akses: null,
        nama_akses: null,
        url: null,
        tipe_akses: null,
        sequence: this.count,
        head: null,
      };
    },
  },
};
</script>
